import React from "react";
import catchUpImage from "@images/home-page/catchUpImage.svg";
import catchUpImageHuge from "@images/home-page/catchUpImageHuge.svg";
import CircleBlur from "@common/CircleBlur/CircleBlur";
import {
  Description,
  SecondaryTitle,
} from "../../../../styles/CommonStyles";
import {
  StyledCatchUpSectionWrapper,
  StyledCatchUpSectionContainer,
  StyledHoverContainer,
  StyledTextContainer,
  StyledBorderImage,
  StyledChildrenContainer
} from "./styled";

const CrawlableSecondCatchUpSection = ({
  isAnimated = false,
  hasBigImage = false,
  title,
  description,
  children
}) => {
  return (
    <StyledCatchUpSectionWrapper className="catchup-section__second">
      <StyledCatchUpSectionContainer
        isAnimated={isAnimated}
        isBackground={!hasBigImage}
      >
        <StyledHoverContainer isAnimated={isAnimated}>
          {isAnimated && (
            <CircleBlur
              width="54.375rem"
              height="18.75rem"
              blur="blur(100px)"
            />
          )}
          <StyledBorderImage
            src={hasBigImage ? catchUpImageHuge : catchUpImage}
            alt="background image"
            className="catchup-section__image"
            isBigImage={hasBigImage}
          />
          <StyledTextContainer isDescription={!!description}>
            <SecondaryTitle>{title}</SecondaryTitle>
            {description && <Description>{description}</Description>}
          </StyledTextContainer>
          <StyledChildrenContainer>
            {children}
          </StyledChildrenContainer>
        </StyledHoverContainer>
      </StyledCatchUpSectionContainer>
    </StyledCatchUpSectionWrapper>
  );
};

export default CrawlableSecondCatchUpSection;
