import React from "react";
import "./CircleBlur.scss";
import classNames from "classnames";

const CircleBlur = ({
    width,
    color,
    top,
    left,
    right,
    showOnMobile,
    // showOnTablet,
    bottom,
    blur,
    height,
    margin,
}) => {
    const circleBlurClassName = classNames("circle-blur", {
        "circle-blur--mobile": showOnMobile === true,
        // "circle-blur--tablet": showOnTablet === true,
    });

    return (
        <div
            className={circleBlurClassName}
            style={{
                width,
                height,
                margin,
                bottom,
                background: color,
                filter: blur,
                top,
                left,
                right,
            }}
        />
    );
};

CircleBlur.defaultProps = {
    color: "var(--light-purple)",
    width: "570px",
    height: "570px",
    blur: "blur(400px)",
    showOnMobile: false,
    // showOnTablet: false,
};

export default CircleBlur;
