import styled, { css } from "styled-components";
import { ReactSVG } from "react-svg";
import catchUpBackground from "@images/home-page/catchUpBackground.svg";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StyledCatchUpSectionWrapper = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: center;

    & button {
        padding: 0 2rem;
    }
`;

export const StyledBorderImage = styled(ReactSVG)`
    svg {
        height: ${({ isBigImage }) => (isBigImage ? `31rem` : "23rem")};
        width: ${({ isBigImage }) => (isBigImage ? `64rem` : "39rem")};
    }
`;

export const StyledCatchUpSectionContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 62.5rem;
    height: 30rem;
    background: ${({ isBackground }) => isBackground ? `url(${catchUpBackground})` : null};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5rem 0;

    & .circle-blur {
        display: none;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            display: block;
        }
    }

    & .catchup-section__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        & svg {
            transition: 0.5s;

            & path {
                transition: 0.2s;
            }
        }

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            & svg {
                transform: scale(1.15);

                ${({ isAnimated }) => isAnimated &&
                    css`
                        & path {
                            fill: rgba(196, 177, 128, 0.1);
                            stroke: #c4b180;
                        }
                    `}
            }
        }
    }
`;

export const StyledHoverContainer = styled.div`
    height: 100%;
    width: 41.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${({ isAnimated }) => isAnimated &&
        css`
            &:hover .catchup-section__image {
                & svg {
                    transform: scale(1.15);

                    & path {
                        fill: rgba(196, 177, 128, 0.1);
                        stroke: #c4b180;
                    }
                }
            }
        `}
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ isDescription }) => isDescription &&
        css`
            & h2 {
                margin: 0;
            }

            & p {
                margin: 1.5rem 0;
                text-align: center;
            }

            @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                box-sizing: border-box;
                width: 100vw;
                padding: 0 1.5rem;
            }
        `}
`;

export const StyledChildrenContainer = styled.div`
    z-index: 10;
`;